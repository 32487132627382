const toggleEducationContent = () => {
    let openFirstBlock = Array.prototype.slice.call(document.querySelectorAll(".educations-wrapper")) 
    if(openFirstBlock.length > 0) {
        openFirstBlock.forEach((element) => {
            let firstItem = Array.prototype.slice.call(element.querySelectorAll(".wp-block-shri-education"))
            firstItem.forEach((item, index) => {
                if(index === 0) {
                    firstItem[index].querySelector(".education-wrapper__icon-show").classList.add("icon-show-content")
                    firstItem[index].querySelector(".education-wrapper__content").classList.add("show-content")
                }
            })
        })
    }
    let clickCourses = Array.prototype.slice.call(document.querySelectorAll(".educations-wrapper .wp-block-shri-education"))
    clickCourses.forEach((element, indexElement) => {
        element.querySelector(".education-wrapper__row").addEventListener("click",
            function (event) {
                event.preventDefault();
                clickCourses.forEach((item, indexItem) => {
                    if (indexElement === indexItem) {
                        if (element.querySelector(".show-content")) {
                            item.querySelector(".education-wrapper__content").classList.remove("show-content");
                            element.querySelector(".education-wrapper__icon-show").classList.remove("icon-show-content")
                        } else {
                            item.querySelector(".education-wrapper__content").classList.add("show-content")
                            element.querySelector(".education-wrapper__icon-show").classList.add("icon-show-content")
                        }
                    }
                })
            })
    });
}

export default toggleEducationContent 