import toggleSearch from "./components/headerSearch";
import toggleMainMenu from "./components/toggleMainMenu";
import toggleCourseMenu from "./components/MenuNavbar";
import toggleCourseContent from "./components/course";
import toggleEducationContent from "./components/education";
import CourseSideBar from "./components/sidebarSingleCourse"
import mutipleContent from "./components/mutipleContent";
import applyNowHtml from "./components/applynowTab";
import Calendar from "./components/calendar";
import DropdownHandle from "./components/dropdown";
import SlideOfRange from "./components/slideRange";
import calendarToggle from "./components/calendarToggle";
import durationCalendar from "./components/durationCalendar";
import eventTab from "./components/eventTab";
import activeEvent from "./components/activeEventTime"
import EventCalendar from "./components/eventCalendar";
import SearchResults from "./components/searchResults";
import CorporateList from "./components/Corporate";
import jobListing from "./components/jobListing"
import jobListTab from "./components/jobListTab"
import SingleJob from "./components/singleJob";

document.addEventListener('DOMContentLoaded', function() {
    toggleSearch(),
    toggleMainMenu(),
    toggleCourseMenu(),
    toggleCourseContent(),
    toggleEducationContent(),
    CourseSideBar(),
    mutipleContent(),
    applyNowHtml(),
    calendarToggle(),
    durationCalendar(),
    eventTab(),
    activeEvent(),
    jobListing(),
	new Calendar
	new DropdownHandle
	new SlideOfRange
	new EventCalendar
    new SearchResults
    new CorporateList
    new jobListTab
    new SingleJob
})
