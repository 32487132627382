const toggleMainMenu = () => {
    const $ = jQuery
    let mainMenu = $('.shri-mainheader-menu')
    if(mainMenu){
        if(window.matchMedia('(max-width: 1024px)').matches){
            mainMenu.ready(function() {
                $('.first-menu-link, .topheader-mb-link').click(function(e) {
                    if($(this).next('ul.shri-mainheader-second-menu').length > 0 || $(this).next('ul.shri-mainheader-second-menu-lv2').length > 0 ||  $(this).next('ul.topheader-mb-submenu').length > 0) {

                        e.preventDefault()
                        let subMenu1 = $(this).next('ul.shri-mainheader-second-menu')
                        let subMenu2 = $(this).next('ul.shri-mainheader-second-menu-lv2')
                        if(subMenu1){
                            e.preventDefault()
                            subMenu1.slideToggle()
                        }
                        if(subMenu2){
                            e.preventDefault()
                            subMenu2 .slideToggle()
                        }
                        $(this).next('ul.topheader-mb-submenu').slideToggle()
                    }
                })
                $('.shri-mainheader-bar-mb').click(function(e) {
                    e.preventDefault()
                    mainMenu.toggleClass('active-mainmenu')
                    $('body').toggleClass('hiddenscroll')
                    $('.shri-mainheader').toggleClass('active-mainheader')
                })
                $('.shri-mainheader-bar-mb svg').click(function(e) {
                    e.preventDefault()
                    $('.shri-mainheader-bar-mb svg').removeClass('hidden')
                    $(this).addClass('hidden') 
                })
                $('.shri-mainheader-search-mb svg').click(function(e) {
                    e.preventDefault()
                    $('.shri-mainheader-search-mb svg').removeClass('hidden')
                    $(this).addClass('hidden')
                })
            })
        }
    }
    let searchMobile = $('.shri-mainheader-search-layout')
    if(searchMobile){
        $('.shri-mainheader-search-mb').click(function(e) {
            e.preventDefault()
            e.stopPropagation()
            searchMobile.toggleClass('active-search-mb')
            $('body').toggleClass('hiddenscroll')
            $('.shri-mainheader').toggleClass('active-mainheader')
        })
    }
    let mainHeader = document.querySelector('.shri-mainheader');
    if(mainHeader) {
        let iconMenu = Array.prototype.slice.call(mainHeader.querySelectorAll('.shri-mainheader-bar-mb svg, .shri-mainheader-search-mb svg'))
        let logoMenu = mainHeader.querySelector('.shri-mainheader-list .shri-mainheader-logo .custom-logo-link img')
        let logoHeight = mainHeader.querySelector('.shri-mainheader-list .shri-mainheader-logo')
        let firstMenuItem= Array.prototype.slice.call(mainHeader.querySelectorAll('.shri-mainheader-menu .shri-mainheader-first-menu .first-menu-item'))
        let firstMenuLink= Array.prototype.slice.call(mainHeader.querySelectorAll('.shri-mainheader-menu .shri-mainheader-first-menu .first-menu-item .first-menu-link'))
        let loginBtn = mainHeader.querySelector('.shri-mainheader-list .shri-mainheader-member-login')
        let topSticky = mainHeader.getBoundingClientRect().top
        let topDefault = window.pageYOffset + mainHeader.getBoundingClientRect().top
        mainHeader.style.borderBottom = "1px solid #f0f0f0"
        document.addEventListener('scroll', function() {
            let topWindow = document.documentElement.scrollTop
            if(topWindow > topSticky){
                mainHeader.style.borderBottom = "0px solid #f0f0f0"
                mainHeader.classList.add('sticky-mainheader')
                logoMenu.classList.add('sticky-logo')
                logoHeight.classList.add('sticky-logo')
                firstMenuItem.forEach((item) => {
                    item.classList.add('sticky-menu-item')
                })
                firstMenuLink.forEach((item) => {
                    item.classList.add('sticky-first-link')
                });
                loginBtn.classList.add('sticky-member-login')
                if(window.matchMedia('(max-width: 1024px)')){
                    iconMenu.forEach((item) => {
                        item.classList.add('sticky-mainheader-icon')
                    })
                }
            } else {
                mainHeader.style.borderBottom = "1px solid #f0f0f0"
                mainHeader.classList.remove('sticky-mainheader')
                logoMenu.classList.remove('sticky-logo')
                logoHeight.classList.remove('sticky-logo')
                firstMenuItem.forEach((item) => {
                    item.classList.remove('sticky-menu-item')
                })
                firstMenuLink.forEach((item) => {
                    item.classList.remove('sticky-first-link')
                });
                loginBtn.classList.remove('sticky-member-login')
                if(window.matchMedia('(max-width: 1024px)')){
                    iconMenu.forEach((item) => {
                        item.classList.remove('sticky-mainheader-icon')
                    })
                }
            }
            if(topSticky < 0) {
                topSticky = topDefault
            }
        })
    }
    let firstMenuItem = $('.first-menu-link')
    if(firstMenuItem){
        firstMenuItem.mouseover(function() {
            let listSubItem =  $(this).siblings().find('.third-menu-item .third-menu-link')
            if(listSubItem) {
                if(window.matchMedia('(min-width: 1024px)').matches){
                    listSubItem.removeClass('active-third-menu-item')
                    listSubItem.first().addClass('active-third-menu-item')
                    listSubItem.siblings('.shri-mainheader-fouth-menu').removeClass('active-fouth-menu')
                    listSubItem.first().siblings('.shri-mainheader-fouth-menu').addClass('active-fouth-menu')
                }
                listSubItem.mouseover(function() {
                    if($(this).siblings('ul').hasClass('shri-mainheader-fouth-menu')){
                        listSubItem.removeClass('active-third-menu-item')
                        $(this).addClass('active-third-menu-item')
                        listSubItem.siblings('.shri-mainheader-fouth-menu').removeClass('active-fouth-menu')
                        $(this).siblings('.shri-mainheader-fouth-menu').addClass('active-fouth-menu')
                    } else {
                        $(this).addClass('active-third-menu-item')
                        $(this).mouseout(function() {
                            $(this).removeClass('active-third-menu-item')
                        })
                    }
                })
                $(this).mouseout(function() {
                    listSubItem.removeClass('active-third-menu-item')
                    listSubItem.first().addClass('active-third-menu-item')
                    listSubItem.siblings('.shri-mainheader-fouth-menu').removeClass('active-fouth-menu')
                    listSubItem.first().siblings('.shri-mainheader-fouth-menu').addClass('active-fouth-menu')
                })
            }
        })
    }
}

export default toggleMainMenu 