const toggleCourseContent = () => {
    let openFirstBlock = Array.prototype.slice.call(document.querySelectorAll(".courses-wrapper")) 
    if(openFirstBlock.length > 0) {
        openFirstBlock.forEach((element) => {
            let firstItem = Array.prototype.slice.call(element.querySelectorAll(".wp-block-overview-courses"))
            firstItem.forEach((item, index) => {
                if(index === 0) {
                    firstItem[index].querySelector(".overview-courses-wrapper__icon-show").classList.add("icon-show-content")
                    firstItem[index].querySelector(".overview-courses-wrapper__content").classList.add("show-content")
                }
            })
        })
    }
    let clickCourses = Array.prototype.slice.call(document.querySelectorAll(".courses-wrapper .wp-block-overview-courses"))
    if(clickCourses.length > 0) {
        clickCourses.forEach((element, indexElement) => {
            element.querySelector(".overview-courses-wrapper__row").addEventListener("click",
                function (event) {
                    event.preventDefault();
                    clickCourses.forEach((item, indexItem) => {
                        if (indexElement === indexItem) {
                            if (element.querySelector(".show-content")) {
                                item.querySelector(".overview-courses-wrapper__content").classList.remove("show-content");
                                element.querySelector(".overview-courses-wrapper__icon-show").classList.remove("icon-show-content")
                            } else {
                                item.querySelector(".overview-courses-wrapper__content").classList.add("show-content")
                                element.querySelector(".overview-courses-wrapper__icon-show").classList.add("icon-show-content")
                            }
                        }
                    })
                })
        });
    }
}

export default toggleCourseContent 