const toggleSearch = () => {
    let searchBox = document.querySelector('.shri-topheader-search')
    if(searchBox){
        let searhInput = searchBox.querySelector('.shri-topheader-search-input')
        let searchBtn = searchBox.querySelector('.search-on-page')
        let searchMove = searchBox.querySelector('.move-to-search')
        searchBtn.addEventListener('click', function(e) {
            e.preventDefault()
            searchBox.classList.toggle('active-searchBox')
            searhInput.classList.toggle('active-searchInput')
            searchBtn.classList.toggle('active-searchBtn')
            searchMove.classList.toggle('active-searchMove')
        })
    }
}

export default toggleSearch