const toggleSearch = (calendarFilter) => {
	let searchToggle = calendarFilter.querySelector('.search-open')
    if(searchToggle) {
        if(window.matchMedia('(max-width: 976px)').matches){
            searchToggle.addEventListener('click', function() {
                let searchField = calendarFilter.querySelector('.shri-calendar-search-field')
                let searchBtn = calendarFilter.querySelector('.shri-calendar-filter-submit')
                let calendarTab = calendarFilter.querySelector('.shri-calendar-sub-filter')
                if(searchField || searchBtn) {
                    calendarTab.classList.remove('active')
                    searchField.classList.toggle('active')
                    searchBtn.classList.toggle('active')
                }
            })
        }
    }
}
const togglesubMenu = () => {
    const $ = jQuery
    let subMenu = $('.shri-calendar-filter-select--label')
    let corporateMenu = $('.shri-corporate-filter-select--label')
    let monthToggle = $('.shri-months-toggle')
    if(subMenu) {
        if(window.matchMedia('(max-width: 1024px)').matches){
            $('.shri-calendar-filter-select--label').children('.open-icon').addClass('active')
            subMenu.click(function(e) {
                e.preventDefault();
                $(this).next('.shri-calendar-filter-select-data').slideToggle()
                $(this).children('.open-icon').toggleClass('active')
                $(this).children('.close-icon').toggleClass('active')
            })
        }
    }
    if(corporateMenu) {
        if(window.matchMedia('(max-width: 1024px)').matches){
            corporateMenu.click(function(e) {
                e.preventDefault();
                $(this).next('.shri-corporate-filter-select-data').slideToggle()
            })
        }
    }
    if(monthToggle) {
        if(window.matchMedia('(max-width: 1024px)').matches){
            monthToggle.click(function(e) {
                e.preventDefault();
                $(this).next('.shri-months-list').slideToggle()
            })
        }
    }
}

const toggleFilter = (calendarFilter) => {
    let filterBtn = calendarFilter.querySelector('.filter-open')
    if(filterBtn) {
        if(window.matchMedia('(max-width: 1024px)').matches){
            filterBtn.addEventListener('click', function() {
                let calendarTab = calendarFilter.querySelector('.shri-calendar-sub-filter')
                let searchField = calendarFilter.querySelector('.shri-calendar-search-field')
                let searchBtn = calendarFilter.querySelector('.shri-calendar-filter-submit')
                if(calendarTab) {
                    searchField.classList.remove('active')
                    searchBtn.classList.remove('active')
                    calendarTab.classList.toggle('active')
                }
            })
        }
    }
}

const allmonthSelectData = () => {
    let monthFilter = document.querySelector('.shri-all-months')
    if(window.matchMedia('(max-width: 1024px)').matches) {
        if(monthFilter) {
            let monthItem = Array.prototype.slice.call(monthFilter.querySelectorAll('.shri-months-list .shri-months-item'))
            let chairItem = monthFilter.querySelector('a span')
            monthItem.forEach((item) => {
                item.addEventListener('click', function() {
                    let valueItem = item.querySelector('a span').innerHTML
                    chairItem.innerHTML = valueItem
                })
            });
        }
    }
}

const calendarToggle = () => {
    let calendarFilter = document.querySelector('.shri-calendar-filter')
    let corporateFilter = document.querySelector('.shri-corporate-filters')

    
    if(calendarFilter) {
        toggleSearch(calendarFilter)
        togglesubMenu()
        toggleFilter(calendarFilter)
        allmonthSelectData()
    }
    if(corporateFilter) {
        togglesubMenu()
    }
}

export default calendarToggle