import noUiSlider from 'nouislider';

class SlideOfRange{
	constructor() {
		this.elements = Array.prototype.slice.call(document.querySelectorAll('[data-call="SlideOfRange"]'))
		this.elements.length > 0 && this.init()
	}

	
	init(){
		this.elements && this.elements.forEach((item)=>{
			let getMinValue = parseInt(item.getAttribute('min'))
			let getMaxValue = parseInt(item.getAttribute('max'))

			noUiSlider.create(item, {
				start: [1, getMaxValue],
				// tooltips: [false, wNumb({decimals: 1}), true],1
				connect: true,
				range: {
					'min': getMinValue,
					'max': getMaxValue
				},
				step: 1
			});
			let durationSubList = document.querySelectorAll('.shri-duration-filter-list-sub')
			durationSubList.forEach((itemSub) => {
				let durationRangeSlider = Array.prototype.slice.call(itemSub.querySelectorAll('.shri-duration-filter-range'))
					if(durationRangeSlider.length > 0) {
						item.noUiSlider.on('change.one', function () {
							durationRangeSlider.forEach((itemDuration, index) => {
								let startE = Array.prototype.slice.call(itemSub.querySelectorAll('.start-date'))
								let endE = Array.prototype.slice.call(itemSub.querySelectorAll('.end-date'))
								let minVal = itemDuration.querySelector('.noUi-handle').getAttribute('aria-valuenow')
								let maxVal = itemDuration.querySelector('.noUi-handle').getAttribute('aria-valuemax')
								startE.forEach((itemS, indexS) => {
									if(index === indexS) {
										itemS.innerHTML = parseInt(minVal);
									}
								})
								endE.forEach((itemE, indexE) => {
									if(index === indexE) {
										itemE.innerHTML = parseInt(maxVal);
									}
								})
							})
						})
					}
				})
			})
	}
}

export default SlideOfRange
