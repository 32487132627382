const getLine = (element) => {
    var el = element
    var divHeight = el.offsetHeight
    var lineHeight = parseInt(el.style.lineHeight)
    var lines = divHeight / lineHeight;
    
    return parseInt(Math.round(lines))
}

const toggleReadmore = (profileContent) => {
   if(profileContent.length > 0){
    
    const lineRange = 10
    profileContent.forEach((item) => {
        let description = item.querySelector(".shri-profile-content .shri-profile__description")
        let readmore = item.querySelector(".shri-profile-content .shri-profile__read-more")
        description.style.lineHeight = "24px"
        let line = getLine(description);
        if(line < lineRange  ) {
            readmore.style.display = "none"
        } else {
            readmore.style.display = 'block' 
        }
        readmore.addEventListener('click', function(e) {
            e.preventDefault()
            if(description.classList.contains('active')){
                readmore.innerHTML = "Read more"
                description.classList.remove("active")
            } else {
                readmore.innerHTML = "Hide away"
                description.classList.add("active")
            }
        })
        })
   }
}

const Profile = () => {
    
    let profileContent = Array.prototype.slice.call(document.querySelectorAll('.shri-profile'))
    if(profileContent.length > 0) {
       
            toggleReadmore(profileContent) 
    }
}
export default Profile