import inView from 'in-view'
import {scrollTo , scrollIntoView } from 'scroll-js';
import * as Icon from '../icon';
import applyNowHtml from '../applynowTab/index';
import toggleCourseMenu from '../MenuNavbar';

const mainTemplate = (menu) => {
    return  `
        <aside class="shri-toc">
            <div class="shri-toc__wrapper">
                <div class = "shri-toc__wrapper-list">
                    ${menu}
                </div>
                <div class="shri-toc__button">
                    <button class = "apply-form" aria-label="apply-button-course bg-green">
                        Submit Interest
                    </button>
                </div>
            </div>
        </aside>
    `
}
const SideBarMainTemplate = (menuArray) => {
    let menuItemRender = menuArray.map((item,index) => {
        return `
            <div class="shri-toc__item" data-anchor="${index}">
                ${item.innerText}
            </div>
        `
    }).join('\n')
    return mainTemplate(menuItemRender)
}


const stickyBtn = () => {
	return `
		<div class="shri-sticky-button">
			<button class="apply-form">
				Submit Interest
			</button>
		</div>
	`
}

const stickySidebar = (selector) => {
    if(selector) {
        let topSticky = selector.getBoundingClientRect().top;
        let topDefault = window.pageYOffset + selector.getBoundingClientRect().top;
        document.addEventListener('scroll', function() {

            let mainheader = document.querySelector('.shri-mainheader')
            let overviewMenu = document.querySelector('.overview-menu-wrapper')
            let topWindow = document.documentElement.scrollTop + mainheader.offsetHeight + (overviewMenu.offsetHeight * 2) + 100;
            if(topWindow > topSticky){
                selector.classList.add('sticky-sidebar-left-content')
            } else {
                selector.classList.remove('sticky-sidebar-left-content')
            }
            if(topSticky < topDefault) {
                topSticky = topDefault
            }
        })
    }
}

const renderMenuTabAcademic = ( course_template ,course_fee ,figureBlockH,labelBlockM,figureBlockM, intake_date) => {
    
    let courseValue = course_fee;
    if(course_template === "academic_program"){
        return `
    <div class="shri-toc__wrapper_title">
        Course Details
    </div>
    <div class="shri-course__item">
        ${Icon.Intake()}
        <div class = "shri-toc__wrapper-content">
            <h3>
            Next Intake
            </h3>
            <p>
                ${intake_date}
            </p>
        </div>
    </div>
    <div class="shri-course__item">
        ${Icon.Commitment()}

        <div class = "shri-toc__wrapper-content">
                <h3>
                Commitment
                </h3>
                <p>
                ${figureBlockM ? figureBlockM + ' ' + labelBlockM : ''}
                </p>
        </div>
    </div>
    <div class="shri-course__item">
         ${Icon.CDP_Hours()}
        <div class = "shri-toc__wrapper-content">
        <h3>
        CPD Hours
        </h3>
        <p>
        ${figureBlockH ? figureBlockH + ' Hours' : ''}
        </p>
    </div>
    </div>
    `
    } else {
        return `
        <div class="shri-toc__wrapper_title">
            Course Details
        </div>
        <div class="shri-course__item">
        ${Icon.Intake()}
            <div class = "shri-toc__wrapper-content">
                <h3>
                Next Intake
                </h3>
                <p>
                    ${intake_date}
                </p>
            </div>
        </div>
        <div class="shri-course__item">
        ${Icon.Commitment()}

        <div class = "shri-toc__wrapper-content">
                <h3>
                Commitment
                </h3>
                <p>
                ${figureBlockM > 0 ? figureBlockM + ' ' + labelBlockM : ''}
                </p>
        </div>
        </div>
        <div class="shri-course__item">

        ${Icon.Course_Fee()}

            <div class = "shri-toc__wrapper-content">
            <h3>
            Course Fee
            </h3>
            <p>
            ${courseValue > 0 ? '$' + courseValue.toLocaleString('en-US') : ''}
            </p>
        </div>
        </div>
        `
    }
}

const changeSubMenu = () => {
    let pageSelector = document.querySelector('.shri-main')
    let figureBlockM = JSON.parse(atob(pageSelector.getAttribute("figureBlockM")))
    let labelBlockM = JSON.parse(atob(pageSelector.getAttribute("labelBlockM")))
    let figureBlockH = JSON.parse(atob(pageSelector.getAttribute("figureBlockH")))
	let course_fee = JSON.parse(atob(pageSelector.getAttribute("course-fee")))
	let intake_date = document.querySelector(".overview-masthead").getAttribute("course-intake")
	let course_template = pageSelector.getAttribute("course-template")
    let applyBtn = Array.prototype.slice.call(document.querySelectorAll('.apply-form'))
    if(applyBtn.length > 0) {
        applyBtn.forEach((itemApply) => {
            itemApply.addEventListener('click', function() {
                let menu = document.querySelector('.shri-toc__wrapper-list')
                menu.innerHTML = renderMenuTabAcademic(course_template,course_fee, figureBlockH, labelBlockM,figureBlockM,intake_date)
                applyNowHtml();
                changeSubMenu()
            })
        })
    }
}

const addActive = (selector) => {
    let menuItem = Array.prototype.slice.call(selector.querySelectorAll('.shri-toc__item'))
    let contentItem = Array.prototype.slice.call(document.querySelectorAll('.shri-course-main-content .overview-heading-wrapper .text-heading-1'))
    let mainheader = document.querySelector('.shri-mainheader')
    let overviewMenu = document.querySelector('.overview-menu-wrapper')
    let locationTop = contentItem[0].offsetHeight
    let locationBottom = window.innerHeight - mainheader.offsetHeight - overviewMenu.offsetHeight - (contentItem[0].offsetHeight * 2) - 100 ;
    contentItem.forEach((item, index) => {
        item.setAttribute('data-anchor', index)
    })
    inView('.shri-course-main-content .overview-heading-wrapper .text-heading-1')
        .on('enter', function(e) {
            menuItem.forEach((item) => {
                if(item.getAttribute('data-anchor') === e.getAttribute('data-anchor')){
                    menuItem.forEach((item1) => {
                        item1.classList.remove("active")
                    })
                    item.classList.add("active");
                }
            })
        })
    inView('.shri-course-main-content .overview-heading-wrapper .text-heading-1')['options']['offset']['top'] =  locationTop;
    inView('.shri-course-main-content .overview-heading-wrapper .text-heading-1')['options']['offset']['bottom'] =  locationBottom;
    menuItem.forEach((item) => {
        let attribute = item.getAttribute('data-anchor')
        item.addEventListener('click', function(e) {
            contentItem[attribute] && scrollTo( window , {
                top: contentItem[attribute].offsetTop - mainheader.offsetHeight - overviewMenu.offsetHeight - contentItem[attribute].offsetHeight,
                    behavior: 'smooth',
                    easing: 'ease-in-out',
                    duration: 500,
                })
            })
        })
    }

const CourseSideBar = () => {
    let selector = document.querySelector('.shri-course-sidebar')
    let menu = document.querySelector('.overview-menu-wrapper')
    if(menu) {
        if(window.matchMedia('(max-width: 976px)').matches){
            menu.insertAdjacentHTML("afterend", stickyBtn())
        }
    }
    if(selector){
		let pageSelector = document.querySelector('.shri-main')
        let figureBlockM = JSON.parse(atob(pageSelector.getAttribute("figureBlockM")))
        let labelBlockM = JSON.parse(atob(pageSelector.getAttribute("labelBlockM")))
        let figureBlockH = JSON.parse(atob(pageSelector.getAttribute("figureBlockH")))
		let course_fee = JSON.parse(atob(pageSelector.getAttribute("course-fee")))
		let intake_date = document.querySelector(".overview-masthead").getAttribute("course-intake")
		let course_template = pageSelector.getAttribute("course-template")
		let subItemMenu =  Array.prototype.slice.call(document.querySelectorAll('.menu-link-wrapper'))
        
        subItemMenu.forEach((item, index) => {
            if(index === 0) {
                if(subItemMenu[index].classList.contains('active')) {
                    let courseFirstContent = document.querySelector('.shri-course-main-content')
                    let getTitles = Array.prototype.slice.call(
                        courseFirstContent.querySelectorAll('.overview-heading-wrapper .text-heading-1')
                    )
                    if(getTitles.length){
                        selector.innerHTML = SideBarMainTemplate(getTitles)
                    }
                    addActive(selector)
                    stickySidebar(selector)
                }
            }
            item.addEventListener('click', function() {
                    if(index === 0) {
                        if(subItemMenu[index].classList.contains('active')) {
                            let courseFirstContent = document.querySelector('.shri-course-main-content')
                            let getTitles = Array.prototype.slice.call(
                                courseFirstContent.querySelectorAll('.overview-heading-wrapper .text-heading-1')
                            )
                            if(getTitles.length){
                                selector.innerHTML = SideBarMainTemplate(getTitles)
                                applyNowHtml();
                                changeSubMenu()
                            }
                            addActive(selector)
                            stickySidebar(selector)
                            const clickItemMenus = Array.prototype.slice.call(
                                document.querySelectorAll('.menu-link-wrapper,.apply-form')
                            )
                            if(clickItemMenus) {
                                let content = document.querySelector('.shri-container-page')
                                let mainHeader = document.querySelector('.shri-mainheader')
                                let overviewMenu = document.querySelector('.overview-menu-wrapper')
                                let contentTop =  window.pageYOffset + content.getBoundingClientRect().top - (mainHeader.offsetHeight * 2) - ( overviewMenu.offsetHeight * 2)
                                clickItemMenus.forEach((item1) => {
                                    item1.addEventListener('click', function() {
                                        let topWindow = document.documentElement.scrollTop;
                                        if(topWindow > contentTop) {
                                            document.body.scrollTop = contentTop
                                            document.documentElement.scrollTop = contentTop
                                        }
                                    })
                                })
                            }
                        }
                    }
                    else {
                        let menu = document.querySelector('.shri-toc__wrapper-list')
                        menu.innerHTML = renderMenuTabAcademic(course_template,course_fee, figureBlockH,labelBlockM,figureBlockM,intake_date)
                        applyNowHtml();
                        changeSubMenu()
                        const clickItemMenus = Array.prototype.slice.call(
                            document.querySelectorAll('.menu-link-wrapper,.apply-form')
                        )
                        if(clickItemMenus) {
                            let content = document.querySelector('.shri-container-page')
                            let mainHeader = document.querySelector('.shri-mainheader')
                            let overviewMenu = document.querySelector('.overview-menu-wrapper')
                            let contentTop =  window.pageYOffset + content.getBoundingClientRect().top - (mainHeader.offsetHeight * 2) - ( overviewMenu.offsetHeight * 2)
                            clickItemMenus.forEach((item1) => {
                                item1.addEventListener('click', function() {
                                    let topWindow = document.documentElement.scrollTop;
                                    if(topWindow > contentTop) {
                                        document.body.scrollTop = contentTop
                                        document.documentElement.scrollTop = contentTop
                                    }
                                })
                            })
                        }
                    }
            })
        })
        changeSubMenu()
    }
}
export default CourseSideBar
