const DEFAULTS = {
    feature: null
}

const BASE_API_URL = 'wp-json';

class API {

    constructor(options) {
        let feature = options.feature || DEFAULTS.feature;
        this.entryPoint = `${BASE_API_URL}/${feature}`;
    }

    call = (method, value) => {
        let slug = `${this.entryPoint}/${method}`;
        let result;
        if (typeof value === 'object') {
            result = this.callApi(slug, value)
        }
        if (!result) {
            result =  "Can't load Api";
            // eslint-disable-next-line no-console
            console.error(result)
        }
        return result
    }

    getApiUrl = (slug) => {
        // eslint-disable-next-line no-undef
        let apiObjectDecoded = JSON.parse(atob(apiObject));
        return `${apiObjectDecoded.homeUrl}/wp-json/${slug}?_wpnonce=${
			apiObjectDecoded.nonce
		}`
    }

    callApi = (slug, value) => {
        // eslint-disable-next-line no-undef
        let url = this.getApiUrl(slug)
		return fetch(
            url,
            {
                method: 'POST',
                cache: 'no-cache',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({
                    value,
                    // eslint-disable-next-line no-undef
                    wp_rest: JSON.parse(atob(apiObject)).nonce,
                }),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                return data
            })
    }
}

export default API
