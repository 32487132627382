const toggleCourseMenu = () => {
    const $ = jQuery
    // let mainMenu = $('.shri-mainheader-menu')

    /** list item menu **/

    const clickItemMenus = Array.prototype.slice.call(
        document.querySelectorAll('.menu-link-wrapper,.apply-form')
    )
    if (clickItemMenus.length>0) {
        clickItemMenus[0].classList.add("active")
        let showActive = document.querySelector('.page-name-item .page-name-link')
        clickItemMenus.forEach((menu, index) => {
            menu.addEventListener("click", function (event) {
                event.preventDefault();
                if (this.querySelector('a')) {
                    clickItemMenus.forEach(item => item.classList.remove("active"))
                    this.classList.add("active")
                }
                if(menu.classList.contains('active')) {
                    showActive.innerText = menu.innerText 
                }
            })
            if(menu.classList.contains('active')) {
                showActive.innerText = menu.innerText 
            }
        });
        let content = document.querySelector('.shri-container-page')
            let mainHeader = document.querySelector('.shri-mainheader')
            let overviewMenu = document.querySelector('.overview-menu-wrapper')
            let contentTop =  window.pageYOffset + content.getBoundingClientRect().top - (mainHeader.offsetHeight * 2) - ( overviewMenu.offsetHeight * 2)
            clickItemMenus.forEach((item1) => {
                item1.addEventListener('click', function() {
                    let topWindow = document.documentElement.scrollTop;
                    if(topWindow > contentTop) {
                        document.body.scrollTop = contentTop
                        document.documentElement.scrollTop = contentTop
                    }
                })
            })
                
    }
    let showMenuList = $('.list-menu-item')
    if(showMenuList.length>0) {
        if (window.matchMedia("(max-width: 1024px)").matches) {
            let clickIconShow = $(".page-name-item")
            let iconShowList = $(".icon-show-list")
            clickIconShow.click(function (e) {
                e.preventDefault()
                showMenuList.slideToggle()
                iconShowList.children('svg').toggleClass('active')
            })
            iconShowList.click(function (e) {
                e.preventDefault()
                showMenuList.slideToggle()
                iconShowList.children('svg').toggleClass('active')
            })
        }
    }
    let courseMenu = document.querySelector('.shri-main .overview-menu-wrapper');
    if(courseMenu) {
        let mainheader = document.querySelector('.shri-mainheader')
        let menuCourseItem = Array.prototype.slice.call(courseMenu.querySelectorAll(".list-menu-item .menu-link-wrapper"))
        let btnApplyCourse = courseMenu.querySelector('.overview-menu-wrapper__button .button')
        let topSticky = courseMenu.getBoundingClientRect().top;
        let topDefault = window.pageYOffset + courseMenu.getBoundingClientRect().top;
        if (window.matchMedia("(max-width: 1024px)").matches) {
            courseMenu.style.top = "20px"
        } else {
            courseMenu.style.top = "0px"
        }
        document.addEventListener('scroll', function() {
            let topWindow = document.documentElement.scrollTop + ( mainheader.offsetHeight * 2 );
            if(topWindow > topSticky){
                courseMenu.classList.add('sticky-overview')
                courseMenu.style.top = mainheader.offsetHeight + "px"
                courseMenu.style.borderBottom = "1px solid #f0f0f0"
                menuCourseItem.forEach((item) => {
                    item.classList.add('sticky-item')
                })
                btnApplyCourse.classList.add('sticky-btn')
            } else {
                courseMenu.classList.remove('sticky-overview')
                if (window.matchMedia("(max-width: 1024px)").matches) {
                    courseMenu.style.top = "20px"
                } else {
                    courseMenu.style.top = "0px"
                }
                menuCourseItem.forEach((item) => {
                    item.classList.remove('sticky-item')
                })
                btnApplyCourse.classList.remove('sticky-btn')
            }
            if(topSticky < topDefault) {
                topSticky = topDefault
            }
        })
    }
}

export default toggleCourseMenu 