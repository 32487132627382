import API from "../../../client/api";


class SearchResults{
	constructor() {
		this.btnLoadMore = document.querySelector('.shri-search-result-load-more')
		this.listCourseContainer = document.querySelector('.shri-search-result-list')
		this.btnSearch = document.querySelector('.shri-search-result-submit')
		this.loading = document.querySelector('.shri-search-result-load')
		this.topSearch = Array.prototype.slice.call(document.querySelectorAll('.shri-topheader-search, .shri-mainheader-search'))
		this.search = ''
		this.postPerPage = 10
		this.page = 1
		this.today = null

		this.topSearch && this.init()
	}
	fetchApiData(filter){
		const THIS = this;
		let Api = new API({
			feature:'search-results/v1'
		});
		THIS.loading && THIS.loading.classList.add('!flex')
		THIS.btnLoadMore && THIS.btnLoadMore.classList.add('!hidden')
		return Api.callApi('search-results/v1/get',{
			data:filter
		}).then((rsp)=>{
			THIS.loading && THIS.loading.classList.remove('!flex')
			THIS.btnLoadMore && THIS.btnLoadMore.classList.remove('!hidden')
			return rsp
		})
	}

	searchClick() {
		const THIS = this
		let inputSearch = document.querySelector('#searchResult')
		inputSearch && inputSearch.addEventListener("keydown", function(e) {
			if (e.code === 'Enter') {
				e.preventDefault()
				THIS.listCourseContainer.innerHTML = '';
				let valueSearch = inputSearch && inputSearch.value
				THIS.search = valueSearch
				THIS.postPerPage = 10
				THIS.page = 1
				THIS.initFetchData()
			}
		});
		THIS.btnSearch && THIS.btnSearch.addEventListener('click', function() {
			THIS.listCourseContainer.innerHTML = '';
			let valueSearch = inputSearch && inputSearch.value
			THIS.search = valueSearch
			THIS.postPerPage = 10
			THIS.page = 1
			THIS.initFetchData()

		})
	}


	handleFetchDisplayData(array){
		const THIS = this
		let returnInnerHtml = ``
		if(array.length > 0){
			array.forEach((item) => {
				returnInnerHtml += item
			});
			if(parseInt(window.totalPostSearch) === array.length){
				THIS.btnLoadMore && THIS.btnLoadMore.classList.add('!hidden')
			}
		}else{
			returnInnerHtml = `<div class="shri-not-found"><p class="shri-not-found-course">Not results</p></div>`
		}
		if(parseInt(window.totalPostSearch) < array.length){
			THIS.btnLoadMore && THIS.btnLoadMore.classList.remove('!hidden')
		}
		return returnInnerHtml
	}

	getTopSearch() {
		const THIS = this
		let link
		THIS.topSearch?.length && THIS.topSearch.forEach((item) => {
			let inputSearch = item.querySelector('.to-search-result')
			let toSearch = item.querySelector('.move-to-search')
			inputSearch && inputSearch.addEventListener('change', function() {
				THIS.search = inputSearch.value
			})

			toSearch && toSearch.addEventListener('click', function(e) {
				e.preventDefault()
				let url = document.querySelector('.shri-footer')
				let hostname = url.getAttribute('search-link')
				if(THIS.search) {
					link = hostname + '?search=' + THIS.search
				} else {
					link = hostname
				}
				window.location.href = link
			})
			inputSearch && inputSearch.addEventListener("keydown", function(e) {
				if (e.code === 'Enter') {
					e.preventDefault()
					THIS.search = inputSearch.value
					let url = document.querySelector('.shri-footer')
					let hostname = url.getAttribute('search-link')
					if(THIS.search) {
						link = hostname + '?search=' + THIS.search
					} else {
						link = hostname
					}
					window.location.href = link
				}
			});
		})
	}
	setSearchResult() {
		const THIS = this
		let resultsInput = document.querySelector('#searchResult')
		if(resultsInput) {
			let search =  window.location.search
			let textS = search && search.split('?search=')
			let value
			textS && textS.forEach((item) => {
				value = item ? item : ''
			})
			resultsInput.value = value ? value : ''

			THIS.search = value
			THIS.initFetchData()

		}
	}
	loadMoreData(){
		const THIS = this
		THIS.btnLoadMore && THIS.btnLoadMore.addEventListener('click',function(){
			if(parseInt(window.totalPostSearch) > 0) {
				THIS.page += 1
				THIS.initFetchData()
			}
			// THIS.listCourseContainer.insertAdjacentHTML('beforeend',THIS.handleFetchDisplayData(window.rspDataSearchFilter))
		})
	}

	behaviorLoading(callback){
		let htmlPage = document.querySelector('html')
		htmlPage.style.overflow = 'hidden'
		if(callback && typeof callback == 'function'){
			setTimeout(()=>{
				htmlPage.style.overflow = null
				callback()
			},2000)
		}
	}

	initFetchData(){
		const THIS = this
		let dataS = {
			'posts_per_page': THIS.postPerPage,
			'page': THIS.page,
			'searchKey': THIS.search
		}
		THIS.fetchApiData(dataS).then((rsp)=>{
			THIS.today = parseInt(rsp.today)
			if(rsp.data.length > 0){
				window.rspDataSearch = rsp.data
				window.rspDataSearchFilter = rsp.data
				window.totalPostSearch = rsp.total_post

				THIS.listCourseContainer && THIS.listCourseContainer.insertAdjacentHTML('beforeend',THIS.handleFetchDisplayData(window.rspDataSearchFilter))
			}
		})
	}

	reloadDisplay(){
		const THIS = this
		let getDataRspFilter = THIS.handleFetchDisplayData(window.rspDataSearchFilter)
		THIS.behaviorLoading(()=>{
			THIS.listCourseContainer ? THIS.listCourseContainer.innerHTML = getDataRspFilter : ''
		})
	}

	init() {
		const THIS = this

		let search =  window.location.search
		if(!search) {
			THIS.initFetchData()
		}
		THIS.loadMoreData()
		THIS.searchClick()
		THIS.getTopSearch()
		this.setSearchResult()
	}
}


export default SearchResults
