const activeEvent = () => {
    let  shriEventTab = Array.prototype.slice.call(document.querySelectorAll('.shri-carlender-post-time a'))
    if(shriEventTab.length > 0) {
        shriEventTab.forEach( (item, index) => {
            index === 0 && item.classList.add('active')
            item.addEventListener('click', function() {
                shriEventTab.forEach((item2) => {
                    item2.classList.remove('active')
                })
                item.classList.add('active')
            })
        })
    }
}

export default activeEvent
