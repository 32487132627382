import Profile from "../profile";

const changeContent = (contentBody ,overviewItem) => {
    overviewItem.forEach( (itemMenu, index ) => {
        itemMenu.addEventListener('click', () => {

            contentBody.forEach((itemCotent, indexContent) => {
                if(index === indexContent) {
                    contentBody.forEach((item) => {
                        item.classList.remove('active-overview')
                    })
                    let tab = document.querySelector('.shri-apply-now-tab .overview-component');
                    if(tab) {
                        tab.classList.remove('active-overview')
                        itemCotent.classList.add('active-overview')   
                        let checkProfile = itemCotent.querySelector('.shri-profile')
                        if(checkProfile && !itemCotent.classList.contains('has-come')) {
                            Profile()
                            itemCotent.classList.add('has-come')
                        }
                    }
                }
            })
        })
    });
    let initial = overviewItem?.[0]
    initial && initial.click()
}

const mutipleContent = () => {
    let contentBody = Array.prototype.slice.call(document.querySelectorAll('.overview-component'))
    if(contentBody) {
        let overviewItem = Array.prototype.slice.call(document.querySelectorAll('.overview-menu-wrapper .list-menu-item .menu-link-wrapper'))
        changeContent(contentBody ,overviewItem);
    }
}
export default mutipleContent