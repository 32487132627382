const openDurationSub = (durationLink, durationSub) => {
    if(durationLink.length > 0 && durationSub.length > 0) {
        durationLink.forEach((item, index) => {
            item.addEventListener('click', function(e){
                e.preventDefault()
                durationLink.forEach((item) => {
                    item.classList.remove('active-btn')
                })
                item.classList.add('active-btn')
                durationSub.forEach((itemS, indexS) => {
                    if(index === indexS) {
                        durationSub.forEach((itemS) => {
                            itemS.classList.remove('active')
                        })
                        itemS.classList.add('active')
                    }
                })
            })
        });
    }
}

const durationCalendar = () => {
    let durationTab = document.querySelector('.shri-duration-filter-list')
    if(durationTab) {
        let durationLink = Array.prototype.slice.call(durationTab.querySelectorAll('.shri-duration-filter-link'))
        let durationSub = Array.prototype.slice.call(document.querySelectorAll('.shri-duration-filter-sub'))
        openDurationSub(durationLink, durationSub)
    }
}

export default durationCalendar