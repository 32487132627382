const applyNowHtml = () => {
    let  shriContent = Array.prototype.slice.call(document.querySelectorAll('.shri-apply-now-tab'))
    if(shriContent.length > 0) {
        let applyBtn = Array.prototype.slice.call(document.querySelectorAll('.apply-form'))
        let overviewPage = Array.prototype.slice.call(document.querySelectorAll('.overview-component'))
        let menuitem = Array.prototype.slice.call(document.querySelectorAll('.list-menu-item .menu-link-wrapper'))
       if(applyBtn.length > 0) {
        applyBtn.forEach((itemApply) => {
          itemApply.addEventListener('click', function() {
            overviewPage.forEach( (item) => {
                item.classList.remove('active-overview')
            })
            menuitem.forEach( (item) => {
              item.classList.remove('active')
            })
            shriContent.forEach((item) => {
              let tab = item.querySelector('.overview-component');
              if(tab) {
                tab.classList.add('active-overview')
              }
            })
            
          })
        })
       }
    }
}

export default applyNowHtml
