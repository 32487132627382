import API from "../../../client/api";

class EventCalendar{
	constructor() {
		this.element = document.querySelector('.shri-calendar-filter')
		this.checkTemplate = document.querySelector('.shri-calendar-event')
		this.btnLoadMore = document.querySelector('.shri-calendar-loadmore-btn')
		this.listCourseContainer = document.querySelector('.shri-calendar-event-list-event')
		this.statusTab = Array.prototype.slice.call(document.querySelectorAll('.shri-carlender-post-time a'))
		this.queryInput = document.querySelector('#query')
		this.filterCourse = this.filterQuery = this.mode_study = this.monthsFilter = this.dayNumberFilter = this.monthNumberFilter = null
		this.pageOffset = 1
		this.funding = []
		this.checkMedia = window.matchMedia('(max-width:1024px)').matches
		this.start = 0
		this.end = 16
		this.queryDefault = {
			"study":this.filterCourse,
			"mode_of_event":this.mode_study,
		}
		this.filterDuration = ''
		this.search = ''
		this.postPerPage = 16
		this.monthTimeRelease = -1
		this.today = null
		this.element && this.checkTemplate && this.init()
	}

	fetchApiData(filter){
		let Api = new API({
			feature:'event-data/v1'
		});
		return Api.callApi('event-data/v1/get',{
			data:filter
		}).then((rsp)=>{
			return rsp
		})
	}

	handleFetchDisplayData(array,start = 0 ,end = 16){
		const THIS = this
		let returnInnerHtml = ``
		let getStatus = THIS.checkUpPastEvent()
		if(array.length > 0){
			for (let i = start; i < end; i++){
				if(!array[i]){
					THIS.btnLoadMore.classList.add('none')
					break;
				}
				let item = array[i]
				returnInnerHtml += item.template
			}
		}else{
			if(getStatus === 'past'){
				returnInnerHtml = `<p class="shri-not-found-course">No results.</p>`
			} else {
				returnInnerHtml = `<p class="shri-not-found-course">There are no upcoming events at this time.</p>`
			}
		}
		if(end < array.length){
			THIS.btnLoadMore.classList.remove('none')
		}
		return returnInnerHtml
	}

	submitData() {
		const THIS = this
		let maxPost = 16
		let buttonLoadMore = this.btnLoadMore
		THIS.filterData()
	}



	loadMoreData(){
		const THIS = this
		this.btnLoadMore && this.btnLoadMore.addEventListener('click',function(){
			THIS.start = THIS.start + THIS.postPerPage
			THIS.end = THIS.end + THIS.postPerPage
			THIS.listCourseContainer.insertAdjacentHTML('beforeend',THIS.handleFetchDisplayData(window.rspDataFilter,THIS.start,THIS.end))
		})
	}

	behaviorLoading(callback){
		let filterCalendar = document.querySelector('.shri-calendar-filter')
		let calenderDisplayListing = document.querySelector('.shri-calendar-list-course')
		let getOverlay = document.querySelector('.shri-calendar-overlays')
		let htmlPage = document.querySelector('html')
		getOverlay.classList.add('active')
		htmlPage.style.overflow = 'hidden'
		if(callback && typeof callback == 'function'){
			setTimeout(()=>{
				htmlPage.style.overflow = null
				getOverlay.classList.remove('active')
				callback()
			},2000)
		}
	}

	buttonSubmitFilter(){
		const THIS = this
		let buttonSubmit = document.querySelector('.shri-filter-submit-btn')
		buttonSubmit && buttonSubmit.addEventListener('click',function(){
			THIS.pageOffset = 1
			THIS.submitData()
		})
	}

	checkTypeSelect(StyleString,value){
		const THIS = this
		switch (StyleString) {
			case 'type-event':
				THIS.queryDefault.study = parseInt(value)
				break;
			case 'mode-of-event':
				THIS.queryDefault.mode_of_event = parseInt(value)
				break
			case 'member_only':
				THIS.queryDefault.member_only = value
				break
			case 'free_event':
				THIS.queryDefault.free_event = value
				break
			case 'all-months':
				THIS.monthTimeRelease = value
				break
		}
	}

	filterDataSingle(){
		const THIS = this
		let getSingleFilter = Array.prototype.slice.call(document.querySelectorAll('[single-filter="true"]'))
		if(getSingleFilter.length){
			getSingleFilter.forEach((item)=>{
				item.addEventListener('click',function (){

					let getTypeFilterValue = this.getAttribute('type-select')
					let value = this.getAttribute('value')
					THIS.pageOffset = 1
					if(this.classList.contains('active')){
						THIS.checkTypeSelect(getTypeFilterValue,null)
					}else{
						THIS.checkTypeSelect(getTypeFilterValue,value)
					}
					THIS.filterData()
					this.classList.toggle('active')
				})
			})
		}
	}

	filterDataEachSelect(){
		const THIS = this
		let getArrItemQuery = Array.prototype.slice.call(document.querySelectorAll('.shri-select-item'))
		getArrItemQuery.length > 0 && getArrItemQuery.forEach((item)=>{
			item.addEventListener('click',function(){
				let getTypeFilter = item.closest('[type-select]')
				if(getTypeFilter){
					let getTypeFilterValue = getTypeFilter.getAttribute('type-select')
					let valueItem = item.getAttribute('value')
					THIS.pageOffset = 1
					THIS.checkTypeSelect(getTypeFilterValue,valueItem)
					THIS.filterData()
				}
			})
		})
	}

	initFetchData(){
		const THIS = this
		this.fetchApiData().then((rsp)=>{
			THIS.today = parseInt(rsp.today)
			if(rsp.data.length > 0){

				window.rspData = rsp.data
				window.rspDataFilter = rsp.data
				THIS.lastStepFilter()

				THIS.listCourseContainer.innerHTML = THIS.handleFetchDisplayData(window.rspDataFilter)
				THIS.clickTabEventStatus()
			}
		})
	}

	lastStepFilter(){
		const THIS = this
		let getStatus = THIS.checkUpPastEvent()
		if(getStatus === 'past'){
			if(THIS.monthTimeRelease == -1){
				window.rspDataFilter = window.rspDataFilter.filter((item) => {
					return item.intake < THIS.today
				})
			}else if(THIS.monthTimeRelease > 0 ){
				window.rspDataFilter = window.rspDataFilter.filter(item => item.intake < THIS.today && item.intakes_release_month == THIS.monthTimeRelease)
			}
		}else{
			if(THIS.monthTimeRelease == -1){
				window.rspDataFilter = window.rspDataFilter.filter((item) => {
					return item.intake > THIS.today
				})
			}else if(THIS.monthTimeRelease > 0 ){
				window.rspDataFilter = window.rspDataFilter.filter(item => item.intake > THIS.today && item.intakes_release_month == THIS.monthTimeRelease)
			}
		}
	}

	clickTabEventStatus(){
		const THIS = this
		THIS.statusTab.forEach((item)=>{
			item.addEventListener('click',function(){
				THIS.filterData()
			})
		})
	}

	filterData(){
		const THIS = this
		let tempArr = []
		let query = THIS.queryDefault
		let convertQueryToArr = Object.keys(query).map((item)=>{
			let obj = {}
			obj[item] = query[item]
			return obj
		})
		if(window.rspData.length > 0){

			tempArr = window.rspData.filter((item)=>{
				let itemValidate = true
				if(convertQueryToArr.length > 0){
					for(let i = 0; i < convertQueryToArr.length; i++){
						let getObjectKeys = Object.keys(convertQueryToArr[i])[0]
						let queryKey = convertQueryToArr[i][getObjectKeys]
						if(convertQueryToArr[i][getObjectKeys] !== null){

							if(item[getObjectKeys]){
								if(typeof queryKey !== 'object' && typeof item[getObjectKeys] !== 'object' && queryKey != item[getObjectKeys]){
									itemValidate = false
									break
								}else if((typeof item[getObjectKeys] === 'object' && !item[getObjectKeys][0]) || queryKey === 'object' || Array.isArray(item[getObjectKeys])){
									if(Array.isArray(item[getObjectKeys]) &&  getObjectKeys !== 'study')
									{
										let convertItemKeyToString = item[getObjectKeys].map(item => parseInt(item.id)).sort().join('')

										for(let i = 0; i < queryKey.length;i++){
											if(!convertItemKeyToString.includes(queryKey[i].toString())){
												itemValidate = false
												break;
											}
											if(!itemValidate){
												break;
											}
										}
									}else{
										let findItem = item[getObjectKeys].findIndex((keyItem)=> {
											if(keyItem.id == queryKey){
												return keyItem
											}
										})
										if(findItem === -1){
											itemValidate = false
											break;
										}
									}
								}else if(getObjectKeys == 'cpd_hours' || getObjectKeys == 'day_duration' || getObjectKeys == 'month_duration'){
									let min = parseInt(convertQueryToArr[i][getObjectKeys].min)
									let max = parseInt(convertQueryToArr[i][getObjectKeys].max)
									let value = parseInt(item[getObjectKeys])
									if(getObjectKeys == 'cpd_hours'){
										if(value < min || value > max){
											itemValidate = false
											break;
										}
									}else{
										if(THIS.filterDuration == 'durationDays'){
											if((value < min || value > max) || item.mode_duration[0] === 'months'){
												itemValidate = false
												break;
											}
										}else if(THIS.filterDuration === 'durationMonths'){
											if((value < min || value > max) || item.mode_duration[0] === 'days'){
												itemValidate = false
												break;
											}
										}
									}

								}
							}else{

								itemValidate = false
								break;
							}

						}
					}
				}
				if(itemValidate){
					return item
				}
			})
			window.rspDataFilter = tempArr
			if(THIS.queryInput.value !== ''){
				window.rspDataFilter = window.rspDataFilter.filter(item => item.title.toLowerCase().includes(THIS.queryInput.value.toLowerCase()))
			}

			THIS.lastStepFilter()
			THIS.reloadDisplay()
		}
	}

	checkUpPastEvent(){
		let checkActiveUpPast = document.querySelector('.shri-carlender-post-time .active')
		return checkActiveUpPast.getAttribute('id-tab')
	}

	reloadDisplay(){
		const THIS = this
		let getDataRspFilter = THIS.handleFetchDisplayData(window.rspDataFilter)
		THIS.behaviorLoading(()=>{
			THIS.listCourseContainer.innerHTML = getDataRspFilter
		})
	}


	init() {
		const THIS = this
		THIS.initFetchData()
		// THIS.buttonSubmitFilter()
		// THIS.loadMoreData()
		// THIS.filterDataEachSelect()
		// THIS.filterDataSingle()


	}
}

export default EventCalendar
