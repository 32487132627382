import API from "../../../client/api";

class jobListTab{
	constructor() {
		this.btnLoadMoreJob = document.querySelector('.shri-job-load-more')
		this.listJobContainer = document.querySelector('.shri-job-listing-tab')
		this.btnSearch = Array.prototype.slice.call(document.querySelectorAll('.shri-job-search .go-job-search, .shri-job-search-mb .go-job-search'))  
		this.filter = document.querySelector('.shri-job-submenu')
        this.filterMb = document.querySelector('.shri-menu-job-mb')
        this.loading = document.querySelector('.shri-search-result-load')
        this.btnApply = Array.prototype.slice.call(document.querySelectorAll('.aplly-job-filter')) 
		this.jobType = []
        this.jobLevel = []
        this.jobSpec = []
        this.jobIndust = []
        this.jobDemo = []
        this.search = ''
		this.postPerPage = 10
		this.page = 1
		this.today = null
        this.totalPost = []
        this.loading = true
        this.totalAllPost
		this.listJobContainer && this.init()
	}
	fetchApiData(filter){
		const THIS = this;
		let Api = new API({
			feature:'job-listing/v1/'
		});
		
		return Api.callApi('job-listing/v1/get',{
			data:filter
		}).then((rsp)=>{
			return rsp
		})
	}

    

	handleFetchDisplayData(array){
		const THIS = this
		let returnInnerHtml = ``
		if(array.length > 0){
			array.forEach((item) => {
                
                THIS.totalPost.push(item);
				returnInnerHtml += item
                
			});
            THIS.btnLoadMoreJob && THIS.btnLoadMoreJob.classList.add('!hidden')
			if(parseInt(THIS.totalAllPost) === THIS.totalPost.length || parseInt(THIS.totalAllPost) < this.postPerPage ){
				THIS.btnLoadMoreJob && THIS.btnLoadMoreJob.classList.add('!hidden')
			} else {
                THIS.btnLoadMoreJob && THIS.btnLoadMoreJob.classList.remove('!hidden')
            }
		}else{
            
			returnInnerHtml = `<div class="shri-not-found"><p class="shri-not-found-course">Not results</p></div>`
		}
		return returnInnerHtml
	}
    jobFilter() {
        const THIS = this
        let jobTypeItem = Array.prototype.slice.call(document.querySelectorAll('.shri-job-submenu-select-item .shri-job-submenu-select-item-switch input'))
        let jobTypeItemMb = Array.prototype.slice.call(document.querySelectorAll('.shri-job-submenu-select-item-mb .shri-job-submenu-select-item-switch input'))
        if(!window.matchMedia('(max-width: 1024px)').matches){
            jobTypeItem && jobTypeItem.forEach((item, index) => {
                item.addEventListener('click', function() {
                    item.classList.toggle('selected')
                    let jobTypeItem2 = Array.prototype.slice.call(document.querySelectorAll('.shri-job-submenu-select-item .shri-job-submenu-select-item-switch input'))
                    jobTypeItem2 && jobTypeItem2.forEach((item2) => {
                        if(item2.classList.contains('selected')) {
                            
                            let value = item2.parentElement.parentElement.getAttribute('select-value')
                            let index =  THIS.jobType.indexOf(value)
                            if(index === -1) {
                                THIS.jobType.push(value)
                            }
                        } else {
                            let value = item2.parentElement.parentElement.getAttribute('select-value')
                            let index =  THIS.jobType.indexOf(value)
                            if(index > -1) {
                                THIS.jobType.splice(index, 1);
                            }
                        }
                    })
                })
                if(THIS.loading === true ) {
                    item.click()
                    if(index === jobTypeItem.length -1) {
                        THIS.loading = false
                    }
                }
            })
        } else {
            jobTypeItemMb && jobTypeItemMb.forEach((item, index) => {
                item.addEventListener('click', function() {
                    item.classList.toggle('selected')
                    let jobTypeItem2 = Array.prototype.slice.call(document.querySelectorAll('.shri-job-submenu-select-item-mb .shri-job-submenu-select-item-switch input'))
                    jobTypeItem2 && jobTypeItem2.forEach((item2) => {
                        if(item2.classList.contains('selected')) {
                            let value = item2.parentElement.parentElement.getAttribute('select-value')
                            let index =  THIS.jobType.indexOf(value)
                            if(index === -1) {
                                THIS.jobType.push(value)
                            }
                        } else {
                            let value = item2.parentElement.parentElement.getAttribute('select-value')
                            let index =  THIS.jobType.indexOf(value)
                            if(index > -1) {
                                THIS.jobType.splice(index, 1);
                            }
                        }
                    })
                })
                if(THIS.loading === true ) {
                    item.click()
                    if(index === jobTypeItem.length -1) {
                        THIS.loading = false
                    }
                }
            })
        }
        
        
        if(window.matchMedia('(max-width: 1024px)').matches){
            
            let JobFilterList = Array.prototype.slice.call(THIS.filterMb.querySelectorAll('.shri-job-submenu-dropdown-mb'))
            JobFilterList && JobFilterList.forEach((itemF) => {
                let dataFilter = itemF.getAttribute('drop-filter')
                if(dataFilter === 'level') {
                    let levelItem = Array.prototype.slice.call(itemF.querySelectorAll('.shri-calendar-filter-select .shri-calendar-filter-select-data-mb .shri-select-item'))
                    levelItem && levelItem.forEach((levelI, index) => {
                        if(index === 0) {
                            levelI.classList.add('selected')
                        }
                        levelI.addEventListener('click', function() {
                            THIS.jobLevel = []
                            let value = levelI.getAttribute('value')
                            if(parseInt(value) !== 0) {
                                THIS.jobLevel.push(value)
                            }
                            levelItem.forEach((levelItem) => {
                                levelItem.classList.remove('selected')
                            })
                            levelI.classList.add('selected')
                        })
                    })
                } else if(dataFilter === 'special') {
                    let levelItem = Array.prototype.slice.call(itemF.querySelectorAll('.shri-calendar-filter-select .shri-calendar-filter-select-data-mb .shri-select-item'))
                    levelItem && levelItem.forEach((levelI, index) => {
                        if(index === 0) {
                            levelI.classList.add('selected')
                        }
                        levelI.addEventListener('click', function() {
                            THIS.jobSpec = []
                            let value = levelI.getAttribute('value')
                            if(parseInt(value) !== 0) {
                                THIS.jobSpec.push(value)
                            }
                            levelItem.forEach((levelItem) => {
                                levelItem.classList.remove('selected')
                            })
                            levelI.classList.add('selected')
                        })
                    })
                } else if(dataFilter === 'industry') {
                    let levelItem = Array.prototype.slice.call(itemF.querySelectorAll('.shri-calendar-filter-select .shri-calendar-filter-select-data-mb .shri-select-item'))
                    levelItem && levelItem.forEach((levelI, index) => {
                        if(index === 0) {
                            levelI.classList.add('selected')
                        }
                        levelI.addEventListener('click', function() {
                            THIS.jobIndust = []
                            let value = levelI.getAttribute('value')
                            if(parseInt(value) !== 0) {
                                THIS.jobIndust.push(value)
                            }
                            levelItem.forEach((levelItem) => {
                                levelItem.classList.remove('selected')
                            })
                            levelI.classList.add('selected')
                        })
                    })
                } else if(dataFilter === 'demo') {
                    let levelItem = Array.prototype.slice.call(itemF.querySelectorAll('.shri-calendar-filter-select .shri-calendar-filter-select-data-mb .shri-select-item'))
                    levelItem && levelItem.forEach((levelI, index) => {
                        if(index === 0) {
                            levelI.classList.add('selected')
                        }
                        levelI.addEventListener('click', function() {
                            THIS.jobDemo = []
                            let value = levelI.getAttribute('value')
                            if(parseInt(value) !== 0) {
                                THIS.jobDemo.push(value)
                            }
                            levelItem.forEach((levelItem) => {
                                levelItem.classList.remove('selected')
                            })
                            levelI.classList.add('selected')
                        })
                    })
                }
            })
        } else {
            let JobFilterList = Array.prototype.slice.call(THIS.filter.querySelectorAll('.shri-job-submenu-dropdown'))
            JobFilterList && JobFilterList.forEach((itemF) => {
                let dataFilter = itemF.getAttribute('drop-filter')
                if(dataFilter === 'level') {
                        let levelItem = Array.prototype.slice.call(itemF.querySelectorAll('.shri-calendar-filter-select .shri-calendar-filter-select-data .shri-select-item'))
                        levelItem && levelItem.forEach((levelI) => {
                            levelI.addEventListener('click', function() {
                                THIS.jobLevel = []
                                let value = levelI.getAttribute('value')
                                if(parseInt(value) !== 0) {
                                    THIS.jobLevel.push(value)
                                }
                            })
                        })
                } else if(dataFilter === 'special') {
                    let levelItem = Array.prototype.slice.call(itemF.querySelectorAll('.shri-calendar-filter-select .shri-calendar-filter-select-data .shri-select-item'))
                    levelItem && levelItem.forEach((levelI) => {
                        levelI.addEventListener('click', function() {
                            THIS.jobSpec = []
                            let value = levelI.getAttribute('value')
                            if(parseInt(value) !== 0) {
                                THIS.jobSpec.push(value)
                            }
                        })
                    })
                } else if(dataFilter === 'industry') {
                    let levelItem = Array.prototype.slice.call(itemF.querySelectorAll('.shri-calendar-filter-select .shri-calendar-filter-select-data .shri-select-item'))
                    levelItem && levelItem.forEach((levelI) => {
                        levelI.addEventListener('click', function() {
                            THIS.jobIndust = []
                            let value = levelI.getAttribute('value')
                            if(parseInt(value) !== 0) {
                                THIS.jobIndust.push(value)
                                
                            }
                        })
                    })
                } else if(dataFilter === 'demo') {
                    let levelItem = Array.prototype.slice.call(itemF.querySelectorAll('.shri-calendar-filter-select .shri-calendar-filter-select-data .shri-select-item'))
                    levelItem && levelItem.forEach((levelI) => {
                        levelI.addEventListener('click', function() {
                            THIS.jobDemo = []
                            let value = levelI.getAttribute('value')
                            if(parseInt(value) !== 0) {
                                THIS.jobDemo.push(value)
                            }
                        })
                    })
                }
            })
        }
        
        
        THIS.clickApplyFilter()
    }

    clicktoggleMenuSub() {
        
        const $ = jQuery
        let subMenuJob = document.querySelector('.shri-menu-job-mb')
        let btntoggleJob = document.querySelector('.shri-menu-job-mb-btn .toggle-menu')
        btntoggleJob && btntoggleJob.addEventListener('click', function() {
            subMenuJob && subMenuJob.classList.toggle('active')
        })
        if(window.matchMedia('(max-width: 1024px)').matches){
            let toggleJobMenu = $('.shri-menu-list')
            if(toggleJobMenu) {
                toggleJobMenu.children('.toggle-job-menu').children('.open').addClass('active')
                toggleJobMenu.click(function() {
                    if(!$(this).siblings('.shri-job-listing-submenu-mb').hasClass('active-mb')){
                        toggleJobMenu.siblings('.shri-job-listing-submenu-mb').slideUp()
                        toggleJobMenu.siblings('.shri-job-listing-submenu-mb').removeClass('active-mb')
                        $(this).siblings('.shri-job-listing-submenu-mb').slideDown()
                        $(this).siblings('.shri-job-listing-submenu-mb').addClass('active-mb')
                        toggleJobMenu.children('.toggle-job-menu').children('.open').addClass('active')
                        toggleJobMenu.children('.toggle-job-menu').children('.close').removeClass('active')
                        $(this).children('.toggle-job-menu').children('.open').removeClass('active')
                        $(this).children('.toggle-job-menu').children('.close').addClass('active')
                    } else {
                        $(this).siblings('.shri-job-listing-submenu-mb').slideUp()
                        $(this).siblings('.shri-job-listing-submenu-mb').removeClass('active-mb')
                        $(this).children('.toggle-job-menu').children('.close').removeClass('active')
                        $(this).children('.toggle-job-menu').children('.open').addClass('active')
                    }
                })
            }
        }
    }

    clicktoggleMenuSub() {
        const $ = jQuery
        let subMenuJob =  Array.prototype.slice.call( document.querySelectorAll('.shri-menu-job-mb, .shri-job-search-mb'))
        let btntoggleJob = Array.prototype.slice.call(document.querySelectorAll('.shri-menu-job-mb-btn .toggle-menu,.shri-menu-job-mb-btn .toggle-search'))
        btntoggleJob.length>0 && btntoggleJob.forEach((item, index) => {
            item.addEventListener('click', function() {
                subMenuJob.length > 0 && subMenuJob.forEach((itemTab, indexTab) => {
                    if(index === indexTab) {
                        itemTab.classList.toggle('active')
                    }
                })
                
            })
        })
        if(window.matchMedia('(max-width: 1024px)').matches){
            let toggleJobMenu = $('.shri-menu-list')
            if(toggleJobMenu) {
                toggleJobMenu.children('.toggle-job-menu').children('.open').addClass('active')
                toggleJobMenu.click(function() {
                    if(!$(this).siblings('.shri-job-listing-submenu-mb').hasClass('active-mb')){
                        toggleJobMenu.siblings('.shri-job-listing-submenu-mb').slideUp()
                        toggleJobMenu.siblings('.shri-job-listing-submenu-mb').removeClass('active-mb')
                        $(this).siblings('.shri-job-listing-submenu-mb').slideDown()
                        $(this).siblings('.shri-job-listing-submenu-mb').addClass('active-mb')
                        toggleJobMenu.children('.toggle-job-menu').children('.open').addClass('active')
                        toggleJobMenu.children('.toggle-job-menu').children('.close').removeClass('active')
                        $(this).children('.toggle-job-menu').children('.open').removeClass('active')
                        $(this).children('.toggle-job-menu').children('.close').addClass('active')
                    } else {
                        $(this).siblings('.shri-job-listing-submenu-mb').slideUp()
                        $(this).siblings('.shri-job-listing-submenu-mb').removeClass('active-mb')
                        $(this).children('.toggle-job-menu').children('.close').removeClass('active')
                        $(this).children('.toggle-job-menu').children('.open').addClass('active')
                    }
                })
            }
        }
    }
    
	clickApplyFilter() {
        const THIS = this
        let btn = Array.prototype.slice.call(document.querySelectorAll('.aplly-job-filter')) 
        let submenu = document.querySelector('.shri-menu-job-mb')
        btn && btn.forEach((item) => {
            item.addEventListener('click', function() {
                THIS.scrollTop()
                THIS.totalPost = []
                THIS.page = 1
                if(submenu){
                    submenu.classList.remove('active')
                }
                THIS.listJobContainer ? THIS.listJobContainer.innerHTML = '' : ''
                THIS.initFetchData()
            })
        })
    }

    searchClick() {
		const THIS = this
		let inputSearch = Array.prototype.slice.call(document.querySelectorAll('#searchJob,#searchJobMB')) 


		inputSearch.length>0 && inputSearch.forEach((item) => {
            item.addEventListener("keydown", function(e) {
                if (e.code === 'Enter') {
                    e.preventDefault()
                    THIS.scrollTop()
                    THIS.listJobContainer.innerHTML = '';
                    let valueSearch = item && item.value
                    THIS.search = valueSearch
                    THIS.page = 1 
                    THIS.totalPost = []
                    THIS.initFetchData()
                }
            });
        })
        
		THIS.btnSearch.length>0 && THIS.btnSearch.forEach((itembtn, indexbtn) => {
            itembtn.addEventListener('click', function() {
                THIS.listJobContainer.innerHTML = '';
                THIS.scrollTop()
                let valueSearch 
                inputSearch.length>0 && inputSearch.forEach((item, index) => {
                    if(indexbtn === index) {
                        valueSearch = item && item.value
                    }
                })
                THIS.search = valueSearch
                THIS.page = 1 
                THIS.totalPost = []
                THIS.initFetchData()
            })
        })
        
	}

    loadMoreData(){
		const THIS = this
		THIS.btnLoadMoreJob && THIS.btnLoadMoreJob.addEventListener('click',function(){
			if(parseInt(window.totalPost) > 0) {
				THIS.page += 1
				THIS.initFetchData()
			}
		})
	}

	scrollTop() {
		const $ = jQuery
        if(window.matchMedia('(max-width: 1025px)').matches){
            let distance = $('#searchJob').offset().top
            let heightMenu = $('.shri-mainheader').height() - 20
            let distanceScroll =  distance - heightMenu
            $("html, body").animate({ scrollTop: distanceScroll }, "slow");
            return false;
        } else {
            $("html, body").animate({ scrollTop: 0 }, "slow");
  		    return false;
        }
	}

	initFetchData(){
		const THIS = this
		let dataJ = {
			'posts_per_page': THIS.postPerPage,
			'page': THIS.page,
			'jobType': THIS.jobType,
            'jobLevel': THIS.jobLevel,
            'jobSpec': THIS.jobSpec,
            'jobIndust': THIS.jobIndust,
            'jobDemo': THIS.jobDemo,
            'searchJob': THIS.search
		}
        THIS.loading && THIS.loading.classList.add('!flex')
        THIS.btnLoadMoreJob && THIS.btnLoadMoreJob.classList.add('!hidden')
		THIS.fetchApiData(dataJ).then((rsp)=>{
			THIS.today = parseInt(rsp.today)
            window.rspData = rsp.data
            window.rspDataFilter = rsp.data
            THIS.totalAllPost = rsp.total_post
            THIS.listJobContainer && THIS.listJobContainer.insertAdjacentHTML('beforeend',THIS.handleFetchDisplayData(window.rspDataFilter)) 
            THIS.loading && THIS.loading.classList.remove('!flex')
		})
	}
    
	init() {
		const THIS = this
        THIS.jobFilter()
        THIS.initFetchData()
        THIS.loadMoreData()
        THIS.searchClick()
        THIS.clicktoggleMenuSub()
       
	}
}


export default jobListTab