
class DropdownHandle {
	constructor() {
		this.elements = Array.prototype.slice.call(document.querySelectorAll('[data-call="dropdownHandle"]'))
		this.elements.length > 0 && this.init()
	}

	handleClickField(arrFilterItem,labelDisplay,filterData,typeSelect){
		if(window.matchMedia('(min-width: 1024px)').matches) {
			arrFilterItem.length > 0 && arrFilterItem.forEach((item)=>{
				item.addEventListener('click',function(){
					event.preventDefault()
					let getValueLabel = this.querySelector('span')
					let oldItemSelect = item.closest('[filter-data]').querySelector('.selected')
					if(typeSelect !=='funding'){
						oldItemSelect && oldItemSelect.classList.remove('selected')
						labelDisplay.innerText = getValueLabel.innerText
						item.classList.add('selected')
						filterData.style.transform = null
						filterData.style.opacity = null
						filterData.classList.remove('expand')
					}else{
						item.classList.toggle('selected')
					}
				})
			})
		}
		
	}

	checkClickOutSide(){
		window.addEventListener('click',function(e){
			if(window.matchMedia('(min-width: 1024px)').matches) {
				if (!e.target.closest('.shri-calendar-filter-select')){
					let checkElementExpanding = document.querySelector('[filter-data].expand')
					if(checkElementExpanding){
						checkElementExpanding.style.transform = null
						checkElementExpanding.style.opacity = null
						checkElementExpanding.classList.remove('expand')
						checkElementExpanding.parentElement.classList.remove('active')
	
					}
				}
			}
			
		})
	}

	init(){
		const THIS = this
		THIS.elements.forEach((item)=>{
			let typeSelect = item.getAttribute('type-select')
			let labelFilter = item.querySelector('[label-filter]')
			if(window.matchMedia('(min-width: 1024px)').matches) {
				labelFilter.addEventListener('click',function(){
					let oldActive = document.querySelector('[filter-data].expand')
					let filterData = item.querySelector('[filter-data]')
					let labelDisplay = this.querySelector('span')
					let arrFilterItem = Array.prototype.slice.call(filterData.querySelectorAll('.shri-select-item'))
					THIS.handleClickField(arrFilterItem,labelDisplay,filterData,typeSelect)
						if(!item.getAttribute('type-select') === 'all-months'){
							if(!filterData.style.transform){
								filterData.style.transform = "scaleY(1)"
								filterData.classList.add('expand')
								filterData.style.opacity = 1
								THIS.elements.forEach((itemtwo) => {
									itemtwo.classList.remove('active')
								})
								item.classList.add('active')
							}
						}
						else {
							if(!filterData.style.transform){
								filterData.style.transform = "scaleY(1)"
								filterData.classList.add('expand')
								filterData.style.opacity = 1
								THIS.elements.forEach((itemtwo) => {
									itemtwo.classList.remove('active')
								})
								item.classList.add('active')
							}
						}
					if(oldActive){
						oldActive.style.transform = null
						oldActive.style.opacity = null
						oldActive.classList.remove('expand')
						item.classList.remove('active')
					}
					THIS.checkClickOutSide()
				})
			}
			
		})
	}
}

export default DropdownHandle
