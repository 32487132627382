import API from "../../../client/api";
import 'slick-carousel';

class CorporateList {
	constructor() {
		this.listCourseContainer = document.querySelector('.shri-corporate-list')
		this.loading = document.querySelector('.shri-search-result-load')
		this.pagi = document.querySelector('.shri-corporate-pagination')
		this.filterItem = Array.prototype.slice.call(document.querySelectorAll('.shri-corporate-filter-select-data .shri-select-item'))
		this.filter_by = ''
		this.postPerPage = 30
		this.data
		this.page = 1
		this.offset
		this.temp = 0
		this.today = null
		this.today = null
		this.removeA = 0
		this.checkFilter = false
		this.activePosition = null
		this.check = true
		this.listCourseContainer && this.init()
	}

	fetchApiData(filter) {
		const THIS = this;
		let Api = new API({
			feature: 'corporate-listing/v1'
		});
		THIS.loading ? THIS.loading.classList.add('!flex') : ''
		THIS.pagi ? THIS.pagi.classList.add('!hidden') : ''
		return Api.callApi('corporate-listing/v1/get', {
			data: filter 
		}).then((rsp) => {
			if(parseInt(rsp['total_post']) <= THIS.postPerPage) {
				THIS.pagi ? THIS.pagi.classList.add('!hidden') : ''
			} else {
				THIS.pagi ? THIS.pagi.classList.remove('!hidden') : ''
			}
			THIS.loading ? THIS.loading.classList.remove('!flex') : ''

			return rsp
		})
	}

	handleFetchDisplayData(array) {
		const THIS = this
		let returnInnerHtml = ``
		if (array.length > 0) {
			array.forEach((item) => {
				returnInnerHtml += item
			});
			if (parseInt(window.totalPost) === array.length) {
			}
		} else {
			returnInnerHtml = `<div class="shri-not-found"><p class="shri-not-found-course">Not results</p></div>`
		}
		if (parseInt(window.totalPost) < array.length) {
		}
		return returnInnerHtml
	}

	behaviorLoading(callback) {
		let htmlPage = document.querySelector('html')
		getOverlay.classList.add('active')
		htmlPage.style.overflow = 'hidden'
		if (callback && typeof callback == 'function') {
			setTimeout(() => {
				htmlPage.style.overflow = null
				callback()
			}, 2000)
		}
	}

	filterData(filterList) {
		const THIS = this;
		if (filterList.length > 0) {
			filterList.forEach((item) => {
				item.addEventListener('click', function () {
					THIS.scrollTop()
					THIS.listCourseContainer ? THIS.listCourseContainer.innerHTML = '' : ''
					let notF = Array.prototype.slice.call(document.querySelectorAll('.shri-not-found'))
					notF && notF.forEach((item) => {
						item.classList.add('!hidden')
					})
					THIS.filter_by = item.getAttribute('value')
					THIS.today = null
					THIS.checkFilter = true
					THIS.initFetchData()
				})
			})
		}

	}

	initFetchData() {
		const THIS = this
		let dataS = {
			'posts_per_page': THIS.postPerPage,
			'page': THIS.page,
			'filter': THIS.filter_by,
		}

		let pagination = document.querySelector('.list_paginations')
		this.fetchApiData(dataS).then((rsp) => {
			THIS.today = parseInt(rsp.today)
			if (rsp.data.length > 0) {
				if(THIS.checkFilter === true) {
					pagination.innerHTML = ''
					pagination.classList.remove('slick-initialized')
					pagination.classList.remove('slick-slider')
					THIS.temp = 0
					THIS.pagination(rsp)
					THIS.checkFilter = false
				}
				window.rspData = rsp.data
				window.rspDataFilter = rsp.data
				window.totalPost = rsp.total_post
				THIS.listCourseContainer && THIS.listCourseContainer.insertAdjacentHTML('beforeend', THIS.handleFetchDisplayData(window.rspDataFilter))

			} else {
				
				THIS.listCourseContainer && THIS.listCourseContainer.insertAdjacentHTML("beforebegin", '<div class="shri-not-found"><p class="shri-not-found-course">Not results</p></div>')
			}
			if(this.removeA === 0) {
				THIS.data = rsp
				THIS.pagination(rsp)
				THIS.removeA++
			}
		})
	}



	render(pagination,totalPage) {
		const THIS = this

		let pageHTML = ``;

		for (let i = 1; i <= totalPage; i++) {
			pageHTML += `<div class="page-numbers swiper-slide" value=${i}>${i}</div>`;
		}

		let paginationHTML = pageHTML


		pagination ? pagination.innerHTML = paginationHTML : ''
	}


	pagination(data) {
		const THIS = this
		let pagination = document.querySelector('.list_paginations')
		let listPagi = document.querySelector('.shri-corporate-pagination')
		let totalPage = Math.ceil(data['total_post'] / THIS.postPerPage)
		if(parseInt(totalPage) > 1) {
			THIS.render(pagination,totalPage)
			listPagi && listPagi.classList.remove('!hidden')
			THIS.carouselPagi()
			THIS.clickPagi(pagination)
		}
		
	}

	carouselPagi() {
		const $ = jQuery
		let pagination = $('.list_paginations')
		pagination && pagination.slick({
			slidesToShow: 5,
  			slidesToScroll: 3,
			infinite: false,
			prevArrow: '<button type="button" class="slick-prev page-numbers"><</button>',
			nextArrow: '<button type="button" class="slick-next page-numbers">></button>',
		})
	}

	clickPagi(pagination) {
		const THIS = this;
		let pagiItem = pagination && Array.prototype.slice.call(pagination.querySelectorAll('.slick-list .page-numbers'))
		pagiItem && pagiItem.forEach((item, index) => {
			if(THIS.temp === 0) {
				if(index === 0) {
					item.classList.add('active')
					let value = item.getAttribute('value')
					THIS.page = value

				}
				THIS.temp++
			}
			item.addEventListener('click', function() {
				THIS.scrollTop()
				let value = item.getAttribute('value')
				pagiItem.forEach((item2) => {
					item2.classList.remove('active')
				})
				item.classList.add('active')
				THIS.page = value
				THIS.listCourseContainer ? THIS.listCourseContainer.innerHTML = '' : ''

				THIS.initFetchData()
			})
		})
	}

	scrollTop() {
		const $ = jQuery
		$("html, body").animate({ scrollTop: 0 }, "slow");
  		return false;
	}

	reloadDisplay() {
		const THIS = this
		let getDataRspFilter = THIS.handleFetchDisplayData(window.rspDataFilter)
		THIS.behaviorLoading(() => {
			THIS.listCourseContainer ? THIS.listCourseContainer.innerHTML = getDataRspFilter : ''
		})
	}
	init() {
		const THIS = this
		THIS.initFetchData()
		THIS.filterData(THIS.filterItem)
	}
}


export default CorporateList
