const jobListing = () => {
    const $ = jQuery
    let submenu = $('.shri-job-submenu')
    if(submenu) {
        let selectLabel = $('.shri-job-submenu-dropdown .shri-calendar-filter-select .shri-calendar-filter-select--label')
        selectLabel && selectLabel.click(function() {
            if(!$(this).next('.shri-job-submenu-dropdown .shri-calendar-filter-select .shri-calendar-filter-select-data').hasClass('active')){
                $('.shri-job-submenu-dropdown .shri-calendar-filter-select .shri-calendar-filter-select-data').removeClass('active')
                $('.shri-job-submenu-dropdown .shri-calendar-filter-select .shri-calendar-filter-select-data').slideUp()
                $(this).next('.shri-calendar-filter-select-data').slideDown()
                $(this).next('.shri-calendar-filter-select-data').addClass('active')
            } else {
                
                $(this).next('.shri-calendar-filter-select-data').slideUp()
                $(this).next('.shri-calendar-filter-select-data').removeClass('active')
            }
            

        })
        let item = $('.shri-job-submenu-dropdown .shri-calendar-filter-select .shri-calendar-filter-select-data .shri-select-item')
        item && item.click(function() {
            let value = $(this).children('span').html()
            $(this).siblings('.shri-select-item').removeClass('select')
            $(this).addClass('select')
            $(this).parent('.shri-calendar-filter-select-data').prev('.shri-calendar-filter-select--label').children('span').html(value)
            $(this).parent('.shri-calendar-filter-select-data').removeClass('active')
            $(this).parent('.shri-calendar-filter-select-data').slideUp()
        })
    }
}

export default jobListing