import API from "../../../client/api";


class SingleJob{
	constructor() {
		this.jobRightContent = document.querySelector('.shri-job-container-right')
		
		this.jobRightContent && this.init()
	}
	
    clickCopyLink(){
        const THIS = this
        let input = THIS.jobRightContent.querySelector('.shri-job-company-profile .shri-job-company-copy-link .value-job-link')
        let btnCopy = THIS.jobRightContent.querySelector('.shri-job-company-profile .shri-job-company-copy-link .coppy-job-link')
        let text = THIS.jobRightContent.querySelector('.shri-job-company-profile .shri-job-company-copy-link span')
        btnCopy && btnCopy.addEventListener('click', function() {   
            input.select();
            input.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(input.value);
            text.innerHTML = 'URL copied!'
        })
    }

	init() {
		const THIS = this
        THIS.clickCopyLink()
	}
}


export default SingleJob
