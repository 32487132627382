const toggleTab = (eventTab) => {
    eventTab.forEach((item) => {
        let menuItem = Array.prototype.slice.call(item.querySelectorAll('.shri-event-item'))
        let tabContentItem = Array.prototype.slice.call(item.querySelectorAll('.wp-block-shri-event-content-tab'))
        if(menuItem.length > 0 && tabContentItem.length > 0) {
            menuItem.forEach((itemMenu, indexMenu) => {
                indexMenu === 0 ? menuItem[indexMenu].classList.add('active'): ''
                tabContentItem.forEach((itemTa, indexTa) => {
                    if(indexTa === 0){
                        itemTa.classList.add('active')
                    }
                })
                itemMenu.addEventListener('click', function() {
                    menuItem.forEach((itemM) => {
                        itemM.classList.remove('active')
                    })
                    itemMenu.classList.add('active')
                    tabContentItem.forEach((itemTab, indexTab) => {
                        if(indexMenu === indexTab){
                            tabContentItem.forEach((itemT) => {
                                itemT.classList.remove('active')
                            })
                            itemTab.classList.add('active')
                        }
                    })
                })
            })
        }
    });
}


const eventTab = () => {
    let eventTab = Array.prototype.slice.call(document.querySelectorAll('.shri-event-tab'))
    if(eventTab.length > 0) {
        toggleTab(eventTab)
    }
}

export default eventTab 